import { NavLink } from "react-router-dom";
import React from "react";
const MobileModal = ({ isOpen, handler }) => {
  const ActiveLink = ({ children, href, activeClassName }) => {
    return (
      <NavLink
        to={href}
        onClick={handler}
        className={(navData) => (navData.isActive ? { activeClassName } : "")}
      >
        {children}
      </NavLink>
    );
  };

  return (
    <section
      className={`${
        isOpen ? "h-screen transition-all duration-200 pt-10" : "h-0 "
      } d= md:hidden shadow fixed left-0 right-0 overflow-hidden  top-20  bg-white flex flex-col gap-14 justify-start items-center transition-all duration-200 z-[1000] `}
    >
      <ActiveLink activeClassName="text-red" href="/">
        <span className="cursor-pointer hover:text-red transition-colors duration-200 md:text-xl px-2 text-2xl">
          Home
        </span>
      </ActiveLink>
      <ActiveLink activeClassName="text-red" href="/products">
        <span className="cursor-pointer hover:text-red transition-colors duration-200 md:text-xl px-2 text-2xl">
          Products
        </span>
      </ActiveLink>
      <ActiveLink activeClassName="text-red" href="/about-us">
        <span className="cursor-pointer hover:text-red transition-colors duration-200 md:text-xl px-2 text-2xl">
          About Us
        </span>
      </ActiveLink>
      <ActiveLink activeClassName="text-red" href="/careers">
        <span className="cursor-pointer hover:text-red transition-colors duration-200 md:text-xl px-2 text-2xl">
          Careers
        </span>
      </ActiveLink>
      <ActiveLink activeClassName="text-red" href="/contact-us">
        <span className="cursor-pointer hover:text-red transition-colors duration-200 md:text-xl px-2 text-2xl">
          Contact Us
        </span>
      </ActiveLink>
    </section>
  );
};

export default MobileModal;
