import React, { useState, useEffect } from "react";
import { ItemCard } from "../components/itemCard";

import { PageBanner } from "../components";
import { FilterTabs } from "../components/products";
import productsBanner from "../assets/bg/product_page_banner.jpg";
import all from "../static/data";
import Transition from "./transitions";
export default function Home() {
  const [activeCategory, changeCategory] = useState("all");

  const [endIndex, setEndIndex] = useState(6);
  const [displayData, setDisplayData] = useState(all);

  const incrementEndIndex = () => {
    setEndIndex((prev) => {
      return prev + 6;
    });
  };

  useEffect(() => {
    setEndIndex(6);
    if (activeCategory === "all") setDisplayData(all);
    else setDisplayData(all.filter((i) => i.category === activeCategory));
  }, [activeCategory]);
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Transition>
      <PageBanner bannerImg={productsBanner} text="Products" />
      <FilterTabs handler={changeCategory} active={activeCategory} />
      <section className="max-w-screen-2xl mx-auto flex flex-wrap gap-6 lg:gap-10 items-center justify-center">
        {displayData.slice(0, endIndex).map((i, index) => {
          return <ItemCard data={i} key={index} />;
        })}

        <div className="w-full grid place-content-center blade-y-margin">
          {endIndex < displayData.length ? (
            <button
              onClick={incrementEndIndex}
              className="mx-auto  hero-btn text-red border-red hover:bg-red hover:text-white "
            >
              {endIndex < displayData.length ? "Load More" : "Load Less"}
            </button>
          ) : (
            ""
          )}
        </div>
      </section>
      <a
        href="https://drive.google.com/file/d/1THGPlo6ZSCPNOhre-KXWpp6HjGJlJX2K/view?usp=sharing"
        target="_blank"
        className="catalogue-btn"
        rel="noreferrer"
      >
        Catalogue
      </a>
    </Transition>
  );
}
