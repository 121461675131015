import React from "react";
export const PageBanner = ({ bannerImg, text }) => {
  return (
    <section className="grid place-content-center gap-7 h-52 md:h-80 lg:h-[24rem] bg-cover bg-left-top bg-no-repeat">
      <h1 className="font-poppins text-4xl md:text-6xl lg:text-7xl text-white z-10 ">
        {text}
      </h1>
      <div className=" h-6 lg:h-8 w-30 relative grid place-content-center place-items-center">
        <img alt="" src="/page_banners/banner_star.svg" />
      </div>
      <img
        src={bannerImg}
        alt=""
        className="h-52 md:h-80 lg:h-[24rem] object-cover absolute -z-10 w-full"
        loading="lazy"
      />
    </section>
  );
};
