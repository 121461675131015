import React from "react";
export const FullScreenLoading = () => {
  return (
    <section className=" fixed z-[1000] top-0 left-0 right-0 bottom-0 bg-black bg-opacity-30 grid place-content-center ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200px"
        height="200px"
        className="h-24 w-24 "
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          fill="none"
          stroke="#e21e24"
          stroke-width="10"
          r="35"
          stroke-dasharray="164.93361431346415 56.97787143782138"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1s"
            values="0 50 50;360 50 50"
            keyTimes="0;1"
          ></animateTransform>
        </circle>
      </svg>
    </section>
  );
};
