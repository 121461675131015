import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  HomePage,
  ProductsPage,
  ContactUsPage,
  AboutPage,
  CareersPage,
} from "./screens";

import { NavBar, Footer } from "./components";
import React from "react";
import { AnimatePresence } from "framer-motion";
function App() {
  return (
    <AnimatePresence exitBeforeEnter>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/about-us" element={<AboutPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </AnimatePresence>
  );
}

export default App;
