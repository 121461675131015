import design from "./index.module.css";
import React, { useEffect } from "react";
import aos from "aos";
import "aos/dist/aos.css";

export const InfraStructure = () => {
  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1200,
    });
  }, []);

  return (
    <section className={design.wrapper} data-aos="zoom-in-down">
      <section className=" lg:text-center mx-auto blade-y-margin max-w-2xl lg:max-w-3xl 2xl:max-w-4xl px-4 ">
        <h1 className="font-title  text-red  mb-5  md:mb-6 lg:mb-7  ">
          Infrastructure and Design
        </h1>
        <p className="para-text mt-3  md:mt-4  ">
          Talati &apos; s modern infrastructure and robust design set us apart.
          Our large-scale production house caters to the Food and Hospitality
          Industry. We also have a strong distribution network in the domestic
          and local supplies domain.
        </p>
        <p className="para-text mt-3  md:mt-4">
          Our in-house design unit assures that every product is delivered on
          time, ensuring a smooth demand and supply flow.
        </p>
      </section>
    </section>
  );
};
