import React, { useEffect } from "react";
import aos from "aos";
import "aos/dist/aos.css";

export const FilterTabs = ({ active, handler }) => {
  useEffect(() => {
    aos.init({
      easing: "ease-out-back",
      duration: 1200,
    });
  }, []);

  const TabItem = ({ text, iconSrc, clickCB, id }) => {
    return (
      <section
        onClick={clickCB}
        className={`${
          active === id ? "border-b-4 border-red " : "hover:bg-slate-200"
        } transition-colors duration-200 cursor-pointer hover:bg-slate-200  my-3 py-3  h-32 w-28 md:h-36 md:w-32 lg:h-40  lg:w-36 2xl:h-48 2xl:w-48  flex flex-col flex-nowrap items-center justify-center  text-center relative gap-3`}
      >
        <div className="h-20 md:h-24 lg:h-28 2xl:h-30  relative min-w-full">
          <img
            alt=""
            src={iconSrc}
            loading="lazy"
            className="h-full w-full object-contain"
          />
        </div>
        <span className=" text-lg lg:text-xl text-gray pb-3">{text}</span>
      </section>
    );
  };

  return (
    <section
      className="mx-auto blade-y-margin px-4 w-11/12 xl:w-10/12 2xl:w-11/12 max-w-7xl  flex flex-wrap items-center justify-evenly gap-3"
      data-aos="zoom-in-down"
    >
      <TabItem
        id="all"
        text="All"
        iconSrc="/product_filter/all.png"
        clickCB={() => handler("all")}
      />
      <TabItem
        id="mixes"
        text="Mixes"
        iconSrc="/home_tabs/instant.svg"
        clickCB={() => handler("mixes")}
      />
      <TabItem
        id="dessert"
        text="Desserts"
        iconSrc="/home_tabs/dessert.svg"
        clickCB={() => handler("dessert")}
      />
      <TabItem
        id="flours"
        text="Flours"
        iconSrc="/home_tabs/flour.svg"
        clickCB={() => handler("flours")}
      />
      <TabItem
        id="spices"
        text="Spices"
        iconSrc="/home_tabs/spice.svg"
        clickCB={() => handler("spices")}
      />
      <TabItem
        id="others"
        text="Others"
        iconSrc="/product_filter/others.png"
        clickCB={() => handler("others")}
      />
    </section>
  );
};
