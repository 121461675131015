import React, { useEffect, Suspense } from "react";
import Transition from "./transitions";
const Banner = React.lazy(() => import("../components/homePage/banner"));
const Tabs = React.lazy(() => import("../components/homePage/homeTabs"));
const AboutUs = React.lazy(() => import("../components/homePage/aboutUs"));
const FeaturedProducts = React.lazy(() =>
  import("../components/homePage/featuredProducts")
);
const OwnYourMix = React.lazy(() =>
  import("../components/homePage/ownYourMix")
);
const PartnerShip = React.lazy(() =>
  import("../components/homePage/partnerShips")
);
const GetInTouch = React.lazy(() =>
  import("../components/homePage/getInTouch")
);
export default function Home() {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <Transition>
        <Suspense fallback={<center>Loading</center>}>
          <Banner />
        </Suspense>
        <Suspense fallback={<center>Loading</center>}>
          <Tabs />
        </Suspense>
        <Suspense fallback={<center>Loading</center>}>
          <AboutUs />
        </Suspense>
        <Suspense fallback={<center>Loading</center>}>
          <FeaturedProducts />
        </Suspense>
        <Suspense fallback={<center>Loading</center>}>
          <OwnYourMix />
        </Suspense>
        <Suspense fallback={<center>Loading</center>}>
          <PartnerShip />
        </Suspense>
        <Suspense fallback={<center>Loading</center>}>
          <GetInTouch />
        </Suspense>
      </Transition>

      <a
        href="https://drive.google.com/file/d/1THGPlo6ZSCPNOhre-KXWpp6HjGJlJX2K/view?usp=sharing"
        target="_blank"
        className="catalogue-btn"
        rel="noreferrer"
      >
        Catalogue
      </a>
    </div>
  );
}
