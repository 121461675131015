import design from "./index.module.css";
import { Link } from "react-router-dom";
import React, { useReducer } from "react";
import { NavLink } from "react-router-dom";
import MobileModal from "../mobileModal";

export const NavBar = () => {
  const [isModalOpen, toggleModal] = useReducer((s) => !s, false);
  const ActiveLink = ({ children, href, activeClassName }) => {
    return (
      <NavLink
        to={href}
        className={(navData) => (navData.isActive ? { activeClassName } : "")}
      >
        {children}
      </NavLink>
    );
  };

  const BurgerBtn = () => {
    return (
      <button className=" block md:hidden" onClick={toggleModal}>
        <label className={`${design.burger} cursor-pointer`}>
          <input className="hidden" type="checkbox" />
          <span className="bg-black before:bg-black after:bg-black "></span>
        </label>
      </button>
    );
  };
  return (
    <nav className="font-poppins  sticky top-0 left-0 right-0 bg-white z-[1000] shadow-md text-gray  lg:px-10">
      <div className="lg:w-10/12 mx-auto  flex items-center justify-between gap-4  h-20">
        <div className="w-32 grid place-content-center">
          <Link to="/">
            <img
              alt=""
              src="/globals/logo.png"
              loading="lazy"
              className=" h-16 md:h-auto "
            />
          </Link>
        </div>
        <div className=" flex-nowrap items-center justify-end  hidden md:flex gap-4 ">
          <ActiveLink activeClassName="text-red" href="/" passHref>
            <span className="cursor-pointer hover:text-red transition-colors duration-200 md:text-xl px-2">
              Home
            </span>
          </ActiveLink>
          <ActiveLink activeClassName="text-red" href="/products" passHref>
            <span className="cursor-pointer hover:text-red transition-colors duration-200 md:text-xl px-2">
              Products
            </span>
          </ActiveLink>
          <ActiveLink activeClassName="text-red" href="/about-us" passHref>
            <span className="cursor-pointer hover:text-red transition-colors duration-200 md:text-xl px-2">
              About Us
            </span>
          </ActiveLink>
          <ActiveLink activeClassName="text-red" href="/careers" passHref>
            <span className="cursor-pointer hover:text-red transition-colors duration-200 md:text-xl px-2">
              Careers
            </span>
          </ActiveLink>
          <ActiveLink activeClassName="text-red" href="/contact-us" passHref>
            <span className="cursor-pointer hover:text-red transition-colors duration-200 md:text-xl px-2">
              Contact Us
            </span>
          </ActiveLink>
        </div>
        <BurgerBtn />
        <MobileModal isOpen={isModalOpen} handler={toggleModal} />
      </div>
    </nav>
  );
};
