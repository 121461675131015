import { PageBanner, FormHeader } from "../components";
import { ContactUsForm } from "../components/contactUs";
import contactUsBanner from "../assets/bg/contact_page_banner.jpg";
import React, { useEffect } from "react";
import Transition from "./transitions";
export default function Home() {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  return (
    <Transition>
      <PageBanner bannerImg={contactUsBanner} text="Careers" />
      <FormHeader
        title="Get In Touch"
        subtitle="Share your insights and let’s have a great conversation."
      />
      <ContactUsForm />
      <a
        href="https://drive.google.com/file/d/1THGPlo6ZSCPNOhre-KXWpp6HjGJlJX2K/view?usp=sharing"
        target="_blank"
        className="catalogue-btn"
        rel="noreferrer"
      >
        Catalogue
      </a>
    </Transition>
  );
}
