import {
  Banner,
  Philosophy,
  InfraStructure,
  AuthenticIngredients,
  FlourishingWorldWide,
} from "../components/aboutUs";
import React, { useEffect } from "react";
import Transition from "./transitions";

export default function About() {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  return (
    <Transition>
      <Banner />
      <Philosophy />
      <InfraStructure />
      <AuthenticIngredients />
      <FlourishingWorldWide />
      <a
        href="https://drive.google.com/file/d/1THGPlo6ZSCPNOhre-KXWpp6HjGJlJX2K/view?usp=sharing"
        target="_blank"
        className="catalogue-btn"
        rel="noreferrer"
      >
        Catalogue
      </a>
    </Transition>
  );
}
