import { PageBanner, FormHeader } from "../components";
import { CareersForm } from "../components/careers";
import carrersBanner from "../assets/bg/career_page_banner.jpg";
import React, { useEffect } from "react";
import Transition from "./transitions";
export default function Careers() {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  return (
    <Transition>
      <PageBanner bannerImg={carrersBanner} text="Careers" />
      <FormHeader
        title="Work With Us"
        subtitle="Share your insights and let’s have a great conversation."
      />
      <CareersForm />
      <a
        href="https://drive.google.com/file/d/1THGPlo6ZSCPNOhre-KXWpp6HjGJlJX2K/view?usp=sharing"
        target="_blank"
        className="catalogue-btn"
        rel="noreferrer"
      >
        Catalogue
      </a>
    </Transition>
  );
}
