import React from "react";
import design from "./index.module.css";
export const Banner = ({ bgBanner }) => {
  return (
    <section
      // tailwind util not working
      className={` h-[18rem] md:h-[24rem] bg-careers_banners bg-cover bg-left-top bg-no-repeat  items-center justify-end ${design.master} flex items-center `}
    >
      <div className="md:w-11/12  mx-auto flex flex-col gap-3 px-8">
        <h3 className="font-subtitle text-yellow md:leading-relaxed">
          Discover Your Taste
        </h3>
        <h1 className=" w-9/12 sm:w-6/12 font-title  text-white">
          Explore your passion for eating health
        </h1>
      </div>
    </section>
  );
};
